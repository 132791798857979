<template>
  <div v-show="isAllowed" @click="openModal" class="btn btn-primary">{{ buttonName }}</div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';

export default {
  name: 'booking-sign-btn',
  props: {
    allowedActions: {
      required: true,
      validator: value => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    orderNumber: {
      validator: value => {
        return typeof value === 'string' || value === null;
      },
      required: false,
    },
    bookingInfo: {
      required: true,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'SIGN',
      actionType: 'SELL_TRANSACTION',
    };
  },
  methods: {
    async openModal() {
      this.$store.commit('sellTransaction/SET_SELL_TRANSACTION_UUID', this.applicationUuid);
      console.log('booking info:', this.bookingInfo);
      if (this.bookingInfo.applicant_id != this.$store.state.global.user.id) {
        this.$modal.show('sell-input-unique-number', { uuid: this.applicationUuid });
      } else {
        this.$router.push({
          path: `/booking/sign-otp/${this.applicationUuid}`,
        });
        // NAVIGATE TO SIGN PAGE
        // this.$modal.show('sell-sign-modalmodal--fkp-sign', { uuid: this.applicationUuid });
      }
    },
  },
};
</script>

<style scoped></style>
